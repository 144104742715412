import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomTooltip from '../CustomTooltip';
import CustomChart from '../CustomChart';

const cubejsApi = Utils.cubejsApi();
const md5 = require('md5');

function PassUsers(props: { period: 'day' | 'week' | 'month' | 'quarter' | 'year'; stack: string; chartType: 'bar' | 'line'; title: string }) {
  const segments: Array<string> = [];

  const colors = (word) => makeColor(word);

  const makeColor = (word: string) => {
    const seed = parseInt(md5(word), 16);
    const random = Math.floor(Math.abs(Math.cos(seed) * 16777215) % 16777215);
    let color = random.toString(16);
    while (color.length < 6) {
      color = `0${color}`;
    }
    return `#${color}`;
  };

  return (
    <>
      <QueryRenderer
        query={{
          timeDimensions: [
            {
              dimension: 'PassUsers.activeAt',
              granularity: props.period,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          measures: ['PassUsers.numberOfUsers'],
          order: {
            'PassUsers.activeAt': 'asc',
          },
          filters: [],
          segments,
          dimensions: ['PassUsers.passType'],
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({
                    ...resultSet.normalizePivotConfig(),
                    fillMissingDates: false,
                  });

          const creaedColors = Object.fromEntries(resultSet.seriesNames().map((series) => [series.yValues[0],  colors(series.yValues[0])]));

          return (
            <>
              <CustomChart colors={creaedColors} data={data} resultSet={resultSet} stack={props.stack} period={props.period} type={props.chartType}/>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default PassUsers;
