import React from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';

const cubejsApi = Utils.cubejsApi();

function CostPerCurry(props: {
  shopId: string|null;
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'year';
  options: { [key: string]: any };
  title: string;
}) {
  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const renderCharts = (resultSet) => {
    const charts: Array<React.ReactNode> = [];
    const series = resultSet.seriesNames();

    if (series.length === 0) {
      return <></>;
    }

    charts.push(<Bar key={series[0].key} dataKey={series[0].key} name="GCPコスト" fill="#4285F4" yAxisId="left" />);

    charts.push(
      <Line key={series[1].key} dataKey={series[1].key} name="カレー1杯あたり" stroke="#F4B400" yAxisId="right" dot />,
    );
    return charts;
  };

  return (
    <>
      <QueryRenderer
        query={{
          measures: ['CostPerCurry.cost', 'CostPerCurry.costPerCurry'],
          timeDimensions: [
            {
              dimension: 'CostPerCurry.date',
              granularity: 'month',
            },
          ],
          order: {
            'CostPerCurry.date': 'asc',
          },
          filters: [],
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });
          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <ComposedChart
                  data={data}
                >
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <CartesianGrid />

                  {renderCharts(resultSet)}

                  <Tooltip
                    formatter={(value) =>
                      new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
                    }
                  />
                </ComposedChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default CostPerCurry;
