import React from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import { Filter } from '@cubejs-client/core';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomChart from '../CustomChart';

const cubejsApi = Utils.cubejsApi();

function Stars(props: {
  shopIds: string[],
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'year';
  chartType: 'bar' | 'line';
  stack: string;
  options: { [key: string]: any };
  title: string;
  ravedCustomers: boolean;
  limitLastNDaysActiveShops: number|null;
  excludeZeroYen: boolean;
  includeUnpaid: boolean;
}) {
  const colors = {
    1: '#FF0000',
    2: '#FF6600',
    3: '#FF9933',
    4: '#0066FF',
    5: '#0000FF',
  };

  const filters: Array<Filter> = [
    {
      member: 'Orders.status',
      operator: 'notEquals',
      values: ['canceled'],
    },
  ];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'Orders.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'Orders.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'Orders.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length> 0){
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  if (props.options && (props.options.excludeZeroYen || props.excludeZeroYen)) {
    segments.push('Orders.excludeZeroYen');
  }

  if (!props.includeUnpaid) {
    segments.push('Orders.paidOrder');
  }

  if (props.ravedCustomers) {
    segments.push('Orders.ravedCustomers');
  }

  if (props.limitLastNDaysActiveShops) {
    filters.push({
      member: 'Slots.time',
      operator: 'afterDate',
      values: [dayjs().tz('Asia/Tokyo').subtract(props.limitLastNDaysActiveShops - 1, 'days').startOf('day').format('YYYY-MM-DD')]
    });
  }

  filters.push({
    member: 'Feedback.stars',
    operator: 'gte',
    values: ['0'],
  });

  return (
    <>
      <QueryRenderer
        query={{
          timeDimensions: [
            {
              dimension: 'Orders.cookedAt',
              granularity: props.period,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          measures: ['Orders.count'],
          filters,
          segments,
          dimensions: ['Feedback.stars'],
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });
          return (
            <>
              <CustomChart colors={colors} data={data} resultSet={resultSet} stack={props.stack} period={props.period} type={props.chartType}/>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default Stars;
